import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MDXProvider } from "@mdx-js/react";
import { useLocation } from "@reach/router";
import Logo from "./Logo";
import useGumroadUrl, {
  endOfBlackFriday,
  useIsBlackFriday,
} from "../../use-gumroad-url";
import CTASection from "../CTASection";
import { Link } from "gatsby";

const NavItem = ({ className = "", ...props }) => (
  <a
    {...props}
    className={`no-underline text-grey-darkest hover:text-blue-dark font-bold text-md ${className}`}
  ></a>
);

const SplitbeeLink = ({ href, ...props }) => {
  return (
    <a
      {...props}
      href={href}
      data-splitbee-event={`Visit ${
        typeof props.children === "string" ? props.children : href
      }`}
    />
  );
};

function padTime(num) {
  num = num.toString();
  while (num.length < 2) num = "0" + num;
  return num;
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

export default ({
  children,
  cta,
  title = "Bedrock: Jumpstart your next SaaS product",
  description = "Modern, full-stack Next.js & GraphQL boilerplate with user authentication, subscription payments, teams, invitations, emails and everything else you need",
  image = "https://bedrock.mxstbr.com/meta.png",
}) => {
  const location = useLocation();
  const url = `https://bedrock.mxstbr.com${location.pathname || ""}`;
  const gum = useGumroadUrl();

  const isBlackFriday = useIsBlackFriday();

  useEffect(() => {
    // @ts-ignore
    window.ga?.("require", "linker");
    // @ts-ignore
    window.ga?.("linker:autoLink", ["bedrock.mxstbr.com"]);
  }, []);

  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const diff = endOfBlackFriday - now;
      if (diff < 0) {
        clearInterval(interval);
        return;
      }

      const hours = Math.floor(diff / HOUR);
      const minutes = Math.floor((diff % HOUR) / MINUTE);
      const seconds = Math.floor((diff % MINUTE) / SECOND);

      setCountdown({ hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="antialiased text-gray-900">
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <link
          rel="icon"
          type="image/png"
          href="https://bedrock.mxstbr.com/favicon.png"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <nav className="fixed top-0 z-50 w-full font-sans shadow">
        {isBlackFriday && (
          <div
            className={`flex flex-col sm:flex-row items-center py-3 px-6 ${`bg-gradient-to-r from-amber-500 to-red-500`} text-white font-bold text-center justify-center text-xl`}
          >
            <span>34% off on Black Friday weekend!</span>
            {countdown.hours < 24 &&
              Object.values(countdown).some((val) => val > 0) && (
                <span className="whitespace-pre-wrap">
                  {" "}
                  {padTime(countdown.hours)}:{padTime(countdown.minutes)}:
                  {padTime(countdown.seconds)} left
                </span>
              )}
          </div>
        )}
        <div className="flex flex-col px-6 py-4 text-center bg-white sm:flex-row sm:text-left sm:justify-between sm:items-center">
          <Link to="/" className="self-center sm:self-left">
            <Logo className="h-4 mt-2 mb-1 sm:mt-0 sm:mb-0" />
          </Link>
          <div className="text-gray-600">
            <NavItem
              href="/#tools"
              className="ml-0 sm:ml-8"
              data-splitbee-event="Go to Tools"
            >
              Tools
            </NavItem>
            <NavItem
              href="/#features"
              className="ml-8"
              data-splitbee-event="Go to Features"
            >
              Features
            </NavItem>
            <NavItem
              href="/#faq"
              className="ml-8"
              data-splitbee-event="Go to FAQ"
            >
              FAQ
            </NavItem>
            <NavItem
              href="/redeem"
              className="ml-8"
              data-splitbee-event="Go to Tools"
            >
              Redeem
            </NavItem>
            <NavItem
              href={gum}
              data-splitbee-event="Open Gumroad"
              className="hidden px-4 py-2 ml-8 text-white rounded-md sm:inline bg-gradient-to-r from-amber-500 to-red-500"
            >
              Buy now
            </NavItem>
          </div>
        </div>
      </nav>
      <article className="max-w-2xl px-4 py-10 mx-auto sm:px-6 sm:py-12 lg:max-w-3xl lg:py-16 lg:px-8 xl:max-w-5xl">
        <MDXProvider components={{ a: SplitbeeLink }}>
          <div className="prose sm:prose lg:prose-lg xl:prose-2xl ">
            {children}
          </div>
        </MDXProvider>
        {cta === true && <CTASection />}
      </article>
    </div>
  );
};
