import React from "react";

export default function GradientText(props) {
  return (
    <span
      {...props}
      style={{
        "-webkit-box-decoration-break": "clone",
      }}
      className={`bg-clip-text text-transparent bg-gradient-to-r ${props.className}`}
    />
  );
}
