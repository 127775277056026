import React from "react";
import { Link } from "gatsby";

export default function CTASection() {
  return (
    <div className="bg-gradient-to-br from-amber-500 to-red-500 my-16 text-center py-8 px-6 sm:py-16 sm:px-12 xl:px-20 rounded-lg">
      <h2 className="text-3xl font-extrabold text-white sm:text-4xl mt-0!important">
        Jumpstart your next SaaS product with Bedrock
      </h2>
      <p className="mt-4 text-lg sm:text-xl leading-6 text-amber-100">
        The modern full-stack Next.js &amp; GraphQL boilerplate by @mxstbr with
        authentication, subscription payments, teams, invitations, emails and
        everything else you need
      </p>
      <Link
        to="/"
        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-white hover:bg-red-50 sm:w-auto transition-colors"
      >
        Learn more
      </Link>
    </div>
  );
}
