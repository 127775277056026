import React from "react";

function Input({ label, ...rest }) {
  return (
    <label className="block flex-1">
      <span className="text-gray-700 text">{label}</span>
      <input
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-300 focus:ring focus:ring-amber-200 focus:ring-opacity-50"
        type="text"
        {...rest}
      />
    </label>
  );
}

export default function RedeemForm() {
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );
  const [username, setUsername] = React.useState("");
  const [key, setKey] = React.useState("");
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const existing = localStorage.getItem("bedrock-data");

    if (existing) setData(JSON.parse(existing));
  }, []);

  if (data) {
    return (
      <div className="rounded-md bg-green-50 p-4 alert">
        <div className="flex">
          <div className="flex-shrink-0">
            {/* Heroicon name: solid/check-circle */}
            <svg
              className="text-green-400"
              style={{ height: `1.5em`, width: `1.5em` }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="font-bold text-green-700 mt-0">
              License redeemed! 🎉 (
              <span className="font-mono">{data.key}</span>)
            </p>
            <p className="text-green-700">
              @{data.username} was added to the Bedrock GitHub repository! Go to{" "}
              <a href="https://github.com/mxstbr/bedrock">mxstbr/bedrock</a> and
              follow the instructions in the README.
              <br /> Please also{" "}
              <strong className="text-green-700">
                join the Discord community with your whole team
              </strong>{" "}
              with this invite link (send it to them!):{" "}
              <a href={data.discord}>{data.discord}</a> This is where everybody
              who bought Bedrock hangs out, can ask questions and get help!
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form
      className="space-y-6"
      onSubmit={async (evt) => {
        evt.preventDefault();
        if (
          window.confirm(
            `Are you sure @${username} is correct? Remember, you can only redeem this key once!`
          )
        ) {
          setStatus("loading");
          const res = await fetch(
            `/api/redeem?username=${username}&key=${key}`
          ).then((res) => res.json());
          if (res.error) {
            setStatus("error");
            setError(res.error);
            return;
          }
          if (res.discord) {
            setStatus("idle");
            const data = {
              key,
              username,
              discord: res.discord,
            };
            setData(data);
            localStorage.setItem("bedrock-data", JSON.stringify(data));
          }
        }
      }}
    >
      <div className="block space-y-4 md:flex md:space-y-0 md:space-x-4">
        <Input
          label="GitHub username"
          placeholder="monalisa"
          required
          value={username}
          onChange={(evt) => setUsername(evt.currentTarget.value)}
        />
        <Input
          label="License key"
          placeholder="85DB262A-C19D4B06-A5335A6B-8C079166"
          required
          value={key}
          onChange={(evt) => setKey(evt.currentTarget.value)}
        />
      </div>

      {status === "error" && (
        <div className="text-red-500 font-bold">{error}</div>
      )}

      <button
        disabled={status !== "idle"}
        className="text-white py-2 px-4 rounded-md hidden sm:inline bg-gradient-to-r from-amber-500 to-red-500 font-bold text-base"
        type="submit"
      >
        {status === "loading" ? "Loading..." : "Redeem"}
      </button>
    </form>
  );
}
