import React from "react";
import GradientText from "../GradientText";
import GumroadCTA from "../GumroadCTA";

export default function Hero() {
  return (
    <div className={`mt-32 mx-auto max-w-7xl px-4 sm:px-6 mb-16`}>
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h1>
            {/* <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
              Coming soon
            </span> */}
            <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl text-gray-900">
              Jumpstart your next SaaS product with Bedrock
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-2xl">
            The modern full-stack{" "}
            <GradientText className="from-cyan-500 to-teal-500">
              Next.js
            </GradientText>{" "}
            &{" "}
            <GradientText className="from-purple-500 to-pink-500">
              GraphQL
            </GradientText>{" "}
            boilerplate with user authentication, subscription payments, teams,
            invitations, emails and everything else you need
          </p>
          <div className="sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
            <GumroadCTA className="lg:items-start" />
          </div>
        </div>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative mx-auto w-full rounded-lg shadow-lg">
            <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">
                Watch the video demo to learn more
              </span>
              <div
                style={{
                  position: `relative`,
                  paddingBottom: `56.25%`,
                  height: 0,
                }}
              >
                <iframe
                  src="https://www.loom.com/embed/3f042d955e344b25b799040fe7dab682"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              {/* <img
                className="w-full"
                src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt=""
              /> */}
              {/* <div
                className="absolute inset-0 w-full h-full flex items-center justify-center"
                aria-hidden="true"
              >
                <svg
                  className="h-20 w-20 text-indigo-500"
                  fill="currentColor"
                  viewBox="0 0 84 84"
                >
                  <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                  <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                </svg>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
