import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Author() {
  return (
    <div className="flex flex-row items-center justify-center -mt-6 mb-8">
      <StaticImage
        src="../../images/avatar.jpg"
        alt="Max's Face"
        style={{ margin: 0, borderRadius: "99999px" }}
        imgStyle={{ margin: 0, borderRadius: "99999px" }}
        layout="fixed"
        width={32}
        aspectRatio={1 / 1}
        placeholder="blurred"
      />
      <span className="ml-2 text-gray-500">Max Stoiber</span>
    </div>
  );
}
