import Layout from "../../../../src/components/Layout";
import Letter from "../../../../src/components/Letter";
import Signature from "../../../../src/components/Signature";
import Testimonial from "../../../../src/components/Testimonial";
import GradientText from "../../../../src/components/GradientText";
import InvisibleAnchor from "../../../../src/components/InvisibleAnchor";
import Ref from "../../../../src/components/Reference";
import GumroadCTA from "../../../../src/components/GumroadCTA";
import Opa from "../../../../src/components/Opacity";
import TechnologiesGrid from "../../../../src/components/TechnologiesGrid";
import RedeemForm from "../../../../src/components/RedeemForm";
import * as React from 'react';
export default {
  Layout,
  Letter,
  Signature,
  Testimonial,
  GradientText,
  InvisibleAnchor,
  Ref,
  GumroadCTA,
  Opa,
  TechnologiesGrid,
  RedeemForm,
  React
};