import { useState, useEffect } from "react";
import { useLocation, useNavigate, navigate } from "@reach/router";
import qs from "querystring";

export const endOfBlackFriday = new Date(
  "November 30 2021 09:00 GMT+0100"
).getTime();

export function useIsBlackFriday() {
  const [isIt, setIsIt] = useState(false);

  useEffect(() => {
    if (Date.now() < endOfBlackFriday) setIsIt(true);
  }, []);

  return isIt;
}

export default function useGumroadUrl() {
  const location = useLocation();
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const isBlackFriday = useIsBlackFriday();

  const [url, setUrl] = useState(`https://gum.co/IhaSu/fbk0ej5`);

  useEffect(() => {
    const a = (params.a as string) || localStorage.getItem("a");

    if (a) {
      if (url.indexOf(a) === -1) {
        setUrl(`https://gumroad.com/a/${a}`);
      }

      if (location.search.indexOf("a=") > -1) {
        navigate(location.pathname, { replace: true });
      }

      localStorage.setItem("a", a);
    }
  }, [params]);

  useEffect(() => {
    if (isBlackFriday) {
      setUrl(`${url}/?offer_code=blackfriday`);
    }
  }, [isBlackFriday]);

  return url;
}
