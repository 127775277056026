import React from "react";

export default function Testimonial({
  text,
  name,
  title,
  company,
  avatar,
  logo,
  link,
}) {
  return (
    <section className="py-12 bg-gray-50 rounded relative overflow-hidden xl:py-20">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex justify-center flex-col">
          <div className="flex justify-center align-center">{logo}</div>
          <div className="mt-8">
            <div className="relative max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-10 w-10 text-gray-400 opacity-25"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative my-0">{text}</p>
            </div>
            <footer className="mt-4">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    key={avatar}
                    className="mx-auto h-10 w-10 rounded-full"
                    src={avatar}
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center font-medium">
                  <a
                    href={link}
                    className="text-base font-medium text-gray-900 no-underline hover:text-amber-400 transition-colors"
                  >
                    {name}
                  </a>
                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-amber-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>
                  <div className="text-base font-medium text-gray-500">
                    {title}, {company}
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </section>
  );
}
