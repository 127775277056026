import React from "react";

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 675 93"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          fill="url(#paint0_linear)"
          d="M0 91.813h43.063c19.312 0 31.5-10.126 31.5-25.813v-.125c0-11.813-9.688-20.75-22-21.375v-.438c10.25-1.312 18.062-9.437 18.062-19.624v-.125c0-14.063-10.813-22.688-28.688-22.688H0v90.188zm35.875-74c7.75 0 12.375 3.75 12.375 10v.125c0 6.75-4.875 10.75-14.063 10.75h-11.25V17.811h12.938zm0 35.312c10.063 0 15.375 3.938 15.375 11.125v.125c0 7.313-5.313 11.25-14.688 11.25H22.938v-22.5h12.937z"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M95.77 91.813h61.813V73.374h-38.875v-18.25h36.562v-17.25h-36.562V20.062h38.875V1.625H95.77v90.188z"
        ></path>
        <path
          fill="url(#paint2_linear)"
          d="M182.04 91.813h36.625c27.188 0 43.25-16.813 43.25-45.626v-.124c0-28.688-16.062-44.438-43.25-44.438H182.04v90.188zm22.938-18.5V20.124h10.687c14.438 0 22.938 9.125 22.938 26v.125c0 18-8 27.063-22.938 27.063h-10.687z"
        ></path>
        <path
          fill="url(#paint3_linear)"
          d="M422.83 93.438c27.5 0 44.563-17.938 44.563-46.688v-.125C467.393 17.937 450.268 0 422.83 0c-27.25 0-44.5 17.938-44.5 46.625v.125c0 28.75 17.125 46.688 44.5 46.688zm0-19c-12.875 0-21.125-10.563-21.125-27.688v-.125C401.705 29.5 410.018 19 422.83 19c12.938 0 21.25 10.563 21.25 27.625v.125c0 16.938-8.125 27.688-21.25 27.688z"
        ></path>
        <path
          fill="url(#paint4_linear)"
          d="M531.475 93.438c23.437 0 39.937-15.063 40.75-35.25l.062-.688h-22.062l-.125.688c-1.563 10.062-8.625 16.25-18.563 16.25-12.562 0-20.562-10.376-20.562-27.813v-.063c0-17.25 7.937-27.562 20.5-27.562 10.5 0 17.062 6.625 18.5 16l.125.938h22.062v-.626C571.35 15.125 555.037 0 531.475 0 504.537 0 487.6 17.188 487.6 46.625v.063c0 29.437 16.812 46.75 43.875 46.75z"
        ></path>
        <path
          fill="url(#paint5_linear)"
          d="M594.62 91.813h22.937v-25.5l7.75-10.063 22.5 35.563h26.938L642.057 41.5l30.875-39.875H648.37l-30.375 39.688h-.438V1.624H594.62v90.188z"
        ></path>
        <path
          fill="url(#paint6_linear)"
          d="M358.122 1.813h-22.937v31.062h-11.688l-15.5-31.063h-25.75l18.063 34.5c-9.375 4.063-15.375 14.063-15.375 25.626v.124C284.935 81.189 297.247 92 319.06 92h39.062V1.812zM335.185 49.5v25.188h-13.438c-8.062 0-13.437-5-13.437-12.563V62c0-7.625 5.187-12.5 13.312-12.5h13.563z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="0.06"
          x2="675.06"
          y1="93.813"
          y2="-0.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59E0B"></stop>
          <stop offset="1" stopColor="#EF4444"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h675v93H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
