import Layout from "../../../../src/components/Layout";
import CTASection from "../../../../src/components/CTASection";
import Author from "../../../../src/components/Author";
import { Helmet } from "react-helmet";
import * as React from 'react';
export default {
  Layout,
  CTASection,
  Author,
  Helmet,
  React
};