// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-redeem-mdx": () => import("./../../../src/pages/redeem.mdx" /* webpackChunkName: "component---src-pages-redeem-mdx" */),
  "component---src-pages-thanks-mdx": () => import("./../../../src/pages/thanks.mdx" /* webpackChunkName: "component---src-pages-thanks-mdx" */),
  "component---src-pages-tools-nexus-mdx": () => import("./../../../src/pages/tools/nexus.mdx" /* webpackChunkName: "component---src-pages-tools-nexus-mdx" */),
  "component---src-pages-tools-pothos-mdx": () => import("./../../../src/pages/tools/pothos.mdx" /* webpackChunkName: "component---src-pages-tools-pothos-mdx" */),
  "component---src-pages-tools-prisma-mdx": () => import("./../../../src/pages/tools/prisma.mdx" /* webpackChunkName: "component---src-pages-tools-prisma-mdx" */),
  "component---src-pages-tools-urql-mdx": () => import("./../../../src/pages/tools/urql.mdx" /* webpackChunkName: "component---src-pages-tools-urql-mdx" */)
}

