import React, { useEffect, useRef } from "react";

const Task = ({ children }) => (
  <li className="flex items-center list-none text-gray-600">
    <div className="h-4 w-4 mr-2 bg-amber-400 rounded flex items-center justify-center">
      <svg
        className="w-3 h-3"
        fill="white"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </div>
    <div>{children}</div>
  </li>
);

const Tasks = () => (
  <>
    <Task>Create the Next.js site</Task>
    <Task>Setup TypeScript</Task>
    <Task>Configure ESLint</Task>
    <Task>Make ESLint work well with TypeScript</Task>
    <Task>Configure Prettier</Task>
    <Task>Make Prettier work well with ESLint</Task>
    <Task>Setup pre-commit hooks for ESLint, Prettier and TypeScript</Task>
    <Task>Establish a solid, scalable folder structure</Task>
    <Task>Stop devs from importing server code on the client</Task>
    <Task>Prepare CI with tests and type checking</Task>
    <Task>Automatic dependency updates</Task>
    <Task>Setup basic database schema</Task>
    <Task>Put magic login authentication together</Task>
    <Task>Setup email sending service</Task>
    <Task>Create the basic GraphQL schema</Task>
    <Task>Make development environment fast and solid</Task>
    <Task>Expose the GraphQL schema from an API route</Task>
    <Task>Handle SSR vs. SSG with authentication</Task>
    <Task>Setup projects in the database and API</Task>
    <Task>Build subscription payments system</Task>
    <Task>Sync the necessary Stripe state to the database</Task>
    <Task>Create migration setup and migrate production database from CI</Task>
    <Task>Support multiple paid plans with upgrades & downgrades</Task>
    <Task>Avoid exposing implementation details in the GraphQL API</Task>
    <Task>Make authentication work on deploy previews</Task>
    <Task>Build user invitation system</Task>
    <Task>Avoid externalResolver issues with Next.js and Apollo Server</Task>
    <Task>Abstract API route middleware management in one central place</Task>
    <Task>Handle unauthenticated "accept invitation" link clicks</Task>
    <Task>Setup client-side data fetching</Task>
    <Task>Setup the normalized urql cache for the best user experience</Task>
    <Task>
      Setup GraphQL Code Generator to generate data fetching React hooks
    </Task>
    <Task>Build "Login" page that redirects to "Check your mailbox" page</Task>
    <Task>Handle payments from client with Stripe Checkout</Task>
    <Task>Global GraphQL error handling</Task>
    <Task>Build project dashboard page</Task>
    <Task>Build project settings page</Task>
    <Task>Make sure projects can manage their billing</Task>
    <Task>Implement human-readable and shareable URL paths</Task>
    <Task>Build user-level dashboard page</Task>
    <Task>Build user settings page</Task>
    <Task>Build separate "Sign up" page</Task>
    <Task>Handle global elements like Navbar and Footer</Task>
    <Task>
      Avoid re-creating Prisma client on every hot reload during "next dev"
    </Task>
    <Task>Figure out the best VSCode extensions to use</Task>
    <Task>Setup Cypress end-to-end test setup</Task>
    <Task>Typesafely seed database for end-to-end tests</Task>
    <Task>Support authentication during end-to-end tests</Task>
    <Task>Create the database in CI and connect it to app</Task>
    <Task>Run end-to-end tests in CI</Task>
  </>
);

const Fade = ({ className = "" }) => (
  <div className={`absolute left-0 right-0 h-24 fade ${className}`}></div>
);

export default function TaskScroller() {
  const listRef = useRef<HTMLUListElement>();
  const scrollerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const list = listRef.current;
    const scroller = scrollerRef.current;
    if (!list || !scroller) return;

    let scrollY = scroller.scrollTop,
      oldTimestamp = null;

    function step(newTimestamp) {
      if (oldTimestamp !== null) {
        scrollY +=
          list.scrollHeight * ((oldTimestamp - newTimestamp) / 50000) * -1;
        if (scrollY >= list.scrollHeight) scrollY = 0;

        scroller.scrollTop = scrollY;
      }
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  }, []);

  return (
    <div className="relative pointer-events-none self-start">
      <div>
        <Fade className="top-0" />
        <div
          className="h-80 scrollbar-none relative"
          style={{
            overflowY: `scroll`,
            pointerEvents: `none`,
          }}
          ref={scrollerRef}
        >
          <ul className="list-none m-0" ref={listRef}>
            <Tasks />
          </ul>
          <ul className="list-none m-0">
            <Tasks />
          </ul>
        </div>
        <Fade className="bottom-0 transform rotate-180" />
      </div>
    </div>
  );
}
