import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Signature from "../Signature";

export default ({ children }) => (
  <div className="bg-yellow-50 py-4 md:py-10 px-4 md:px-14 shadow-md my-6 letter">
    {children}

    <div className="flex items-center">
      <StaticImage
        src="../../images/avatar.jpg"
        alt="Max's Face"
        style={{ margin: 0, borderRadius: "99999px" }}
        imgStyle={{ margin: 0, borderRadius: "99999px" }}
        layout="fixed"
        width={80}
        aspectRatio={1 / 1}
        placeholder="blurred"
      />
      <div className="ml-6 flex flex-col items-center justify-center">
        <Signature
          className="h-24 max-w-full"
          style={{ marginBottom: `-2.5em`, zIndex: 1 }}
        />
        <div
          className="mt-1 text-gray-500 text-sm border-gray-500"
          style={{ borderTopWidth: 1 }}
        >
          Max Stoiber (@mxstbr)
        </div>
      </div>
    </div>
  </div>
);
