import React from "react";

const NAV_HEIGHT = 65;

export default function InvisibleAnchor({ id, children }) {
  return (
    <span
      id={id}
      style={{
        marginTop: `-${NAV_HEIGHT * 2}px`,
        paddingBottom: `${NAV_HEIGHT * 2}px`,
        display: "block",
      }}
    >
      {children}
    </span>
  );
}
