import { Link } from "gatsby";
import React from "react";
import useGumroadUrl, { useIsBlackFriday } from "../../use-gumroad-url";

export default ({ className = "" }) => {
  const gum = useGumroadUrl();
  const isBlackFriday = useIsBlackFriday();

  const text = isBlackFriday ? (
    <>
      Buy now for <del>$450</del> $298 (34% off!)
    </>
  ) : (
    <>
      Buy now for <del>$450</del> $396 (12% off!)
    </>
  );

  return (
    <div
      className={`inline-flex flex-col items-center justify-center w-full my-8 ${className}`}
    >
      <a
        className="px-6 py-3 mt-3 mb-2 rounded-lg gumroad-button"
        href={gum}
        target="_blank"
        data-splitbee-event="Open Gumroad"
      >
        {text}
      </a>
      <span className="text-sm text-gray-400">
        <a href="#refund">14 day money-back guarantee</a>
      </span>
    </div>
  );
};
