import React from "react";
import ArrowRight from "../ArrowRight";

export default function MoreLink({ href, children }) {
  return (
    <div className="flex text-amber-500 items-center">
      <ArrowRight /> {children}
    </div>
  );
}
